module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Website of AMMIPRODUCT LLC","short_name":"Website of AMMIPRODUCT LLC","start_url":"/","background_color":"#fff","theme_color":"#000","display":"browser","icon":"src/assets/ammiproduct.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"44f86dc7be972191215c0ece2261f92f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
